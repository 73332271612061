import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label, FormFeedback } from "reactstrap";
import { Formik, Field, Form as FormikForm } from "formik";
import * as Yup from 'yup';
import axios from "axios";
import { ApiUrl, ErrorHandle } from "../../common/GeneralUtils";

// You can define a validation schema if needed, for now it's just basic validation
// const validate = values => {
//     const errors = {};
//     if (!values.name) {
//         errors.name = "Required";
//     }
//     if (!values.email) {
//         errors.email = "Required";
//     } else if (!/\S+@\S+\.\S+/.test(values.email)) {
//         errors.email = "Invalid email address";
//     }
//     if (!values.location) {
//         errors.location = "Required";
//     }
//     if (!values.budget) {
//         errors.budget = "Required";
//     }
//     if (!values.currency) {
//         errors.currency = "Required";
//     }
//     if (!values.projectDescription) {
//         errors.projectDescription = "Required";
//     }
//     return errors;
// };

const FormComponent = ({ setToasts }) => {
    const [loading, setLoading] = useState(false);
    const [currencyList, setCurrencyList] = useState([])
    const [countriesList, setCountriesList] = useState([])


    // Define getCountries as an async function
    const getCurrencies = async () => {
        try {
            const res = await axios.get(`${ApiUrl}/varahi/supported/currencies`); // Assuming no `values` parameter is required for POST request
            setCurrencyList(res?.data?.data?.list); // Set currency state to response data
        } catch (err) {
            console.debug(":Values from get currencies", err);
            let customMessage = "Currencies load failed";
            let type = "User Information";
            let color = "red";
            ErrorHandle(err, setToasts, customMessage, type, color); // Error handling function
        }
    };
    // Define getCountries as an async function
    const getCountries = async () => {
        try {
            const res = await axios.get(`${ApiUrl}/varahi/world/countries`); // Assuming no `values` parameter is required for POST request
            setCountriesList(res?.data?.data?.list); // Set currency state to response data
        } catch (err) {
            console.debug(":Values from get countries", err);
            let customMessage = "Countries load failed";
            let type = "User Information";
            let color = "red";
            ErrorHandle(err, setToasts, customMessage, type, color); // Error handling function
        }
    };

    // Call getCountries function when the component mounts or based on a specific event
    useEffect(() => {
        getCurrencies();
        getCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (values) => {
        setLoading(true); // Start loading before the request
        try {
            await axios.post(`${ApiUrl}/varahi/enquiry`, values).then((res) => {
                let customMessage = "Form submitted successfully!";
                let type = "success";
                let color = "green";
                ErrorHandle(null, setToasts, customMessage, type, color);
            }).catch((error) => {
                console.error("Error submitting the form:", error);
                let customMessage = "There was an error submitting the form.";
                let type = "error";
                let color = "red";
                ErrorHandle(error, setToasts, customMessage, type, color);

            }); // Assuming no `values` parameter is required for POST request
            // Handle success (e.g., show a success message, reset the form, etc.)
        } catch (error) {

            let customMessage = "There was an error submitting the form.";
            let type = "error";
            let color = "red";
            ErrorHandle(error, setToasts, customMessage, type, color);
        } finally {
            setLoading(false); // Stop loading after the request
        }
    };

    return (
        <div>
            <Formik
                initialValues={{
                    clientName: "",
                    clientEmail: "",
                    locationName: "",
                    minBudget: "",
                    maxBudget: "",
                    budgetCurrency: "",
                    projectDescription: ""
                }}
                // validate={validate}
                validationSchema={Yup.object({
                    clientName: Yup.string().required("Name is required"),
                    clientEmail: Yup.string().email("Invalid email address").required("Email is required"),
                    locationName: Yup.string().required("Location is required"),
                    // minBudget: Yup.string().required("Min Budget is required"),
                    minBudget: Yup.number()
                        .required("Please Provide Minimum Budget")
                        .min(100, "Please Provide Valid Minimum Budget, It Should be Minimum 100")
                        .max(10000000, "Please Provide Valid Minimum Budget, It Should be Minimum 100 and Maximum 10000000")
                        .typeError("Please Provide Valid Minimum Budget"),
                    maxBudget: Yup.number()
                        .required("Please Provide Maximum Budget")
                        .min(101, "Please Provide Valid Maximum Budget, It Should be Minimum 101")
                        .max(10000001, "Please Provide Valid Maximum Budget, It Should be Minimum 101 and Maximum 10000001")
                        .typeError("Please Provide Valid Maximum Budget"),
                    // maxBudget: Yup.string().required("Max Budget is required"),
                    budgetCurrency: Yup.string().required("Currency is required"),
                    projectDescription: Yup.string()
                        .required("Please Provide Project Description")
                        .min(50, "Please Provide Valid Project Description, It Should be Minimum 50 Characters in length")
                        .max(500, "Please Provide Valid Project Description, It Should be Maximum 500 Characters in length")
                    // projectDescription: Yup.string().required("Project description is required"),
                })}
                onSubmit={handleSubmit}
            >
                {({ errors, values, touched, handleSubmit, resetForm }) => (
                    <FormikForm onSubmit={handleSubmit} className="contact-form-page">
                        <FormGroup className="form-input m-t-15">
                            <Label htmlFor="clientName">Name *</Label>
                            <Field
                                type="text"
                                name="clientName"
                                placeholder="name"
                                as={Input}
                                maxLength={255}
                                minLength={3}
                                invalid={errors.clientName ? true : false}
                            />
                            {errors.clientName && touched.clientName && <FormFeedback>
                                {errors.clientName}
                            </FormFeedback>}
                        </FormGroup>
                        <FormGroup className="form-input m-t-15">
                            <Label htmlFor="clientEmail">Email *</Label>
                            <Field
                                type="text"
                                name="clientEmail"
                                placeholder="you@gmail.com"
                                as={Input}
                                maxLength={255}
                                minLength={5}
                                invalid={errors.clientEmail ? true : false}
                            />
                            {errors.clientEmail && touched.clientEmail && <FormFeedback>
                                {errors.clientEmail}
                            </FormFeedback>}
                        </FormGroup>
                        <FormGroup className="form-input m-t-15">
                            <Label htmlFor="mobileNumber">Mobile Number</Label>
                            <Field
                                type="text"
                                name="mobileNumber"
                                placeholder="Mobile number"
                                as={Input}
                                invalid={errors.mobileNumber ? true : false}
                                maxLength={10}
                                minLength={8}
                            />
                            {errors.mobileNumber && touched.mobileNumber && <FormFeedback>
                                {errors.mobileNumber}
                            </FormFeedback>}
                        </FormGroup>
                        <FormGroup className="form-input m-t-15">
                            <Label for="locationName">Location *</Label>
                            <Field
                                name="locationName"
                                as={Input}
                                type="select"
                                invalid={errors.locationName ? true : false}
                                disabled={countriesList.length === 0}  // Disable until countries are loaded
                                className="custom-select"  // Add the custom class for styling
                            >
                                <option value="">Select Location</option>
                                {countriesList?.map((lang, inx) => (
                                    <option value={lang?.countryId} key={inx}>
                                        {lang?.countryName}
                                    </option>
                                ))}
                            </Field>
                            {errors.locationName && touched.locationName && <FormFeedback>
                                {errors.locationName}
                            </FormFeedback>}
                        </FormGroup>
                        <div className="budgetCurrency d-flex align-items-start justify-content-between gap-2">
                            <FormGroup className="form-input m-t-15 w-50">
                                <Label for="budgetCurrency">Currency *</Label>
                                <Field
                                    name="budgetCurrency"
                                    as={Input}
                                    type="select"
                                    invalid={errors.budgetCurrency ? true : false}
                                    disabled={currencyList.length === 0}  // Disable until countries are loaded
                                    className="custom-select"  // Add the custom class for styling
                                >
                                    <option value="">Select Currency</option>
                                    {currencyList?.map((lang, inx) => (
                                        <option value={lang?.currencyId} key={inx}>
                                            {lang?.currencyName}
                                        </option>
                                    ))}
                                </Field>
                                {errors.budgetCurrency && touched.budgetCurrency && <FormFeedback>
                                    {errors.budgetCurrency}
                                </FormFeedback>}
                            </FormGroup>
                            <FormGroup className="form-input m-t-15 w-25">
                                <Label htmlFor="minBudget">Min Budget</Label>
                                <Field
                                    type="number"
                                    name="minBudget"
                                    placeholder="Min"
                                    as={Input}
                                    invalid={errors.minBudget ? true : false}
                                />
                                {errors.minBudget && touched.minBudget && <FormFeedback>
                                    {errors.minBudget}
                                </FormFeedback>}
                            </FormGroup>
                            <FormGroup className="form-input m-t-15 w-25">
                                <Label htmlFor="maxBudget">Max Budget</Label>
                                <Field
                                    type="number"
                                    name="maxBudget"
                                    placeholder="Max"
                                    as={Input}
                                    invalid={errors.maxBudget ? true : false}
                                />
                                {errors.maxBudget && touched.maxBudget && <FormFeedback>
                                    {errors.maxBudget}
                                </FormFeedback>}
                            </FormGroup>
                        </div>
                        <div></div>
                        <FormGroup className="form-input form-text-area m-t-15">
                            <Label htmlFor="projectDescription">Project description *</Label>
                            <Field
                                name="projectDescription"
                                as={Input}
                                type="textarea"
                                placeholder="Project description"
                                invalid={errors.projectDescription ? true : false}
                                value={values.projectDescription}
                                maxLength={500}
                            />
                            <small>
                                {500 - values.projectDescription.length} characters
                                remaining
                            </small>
                            {errors.projectDescription && touched.projectDescription && (
                                <FormFeedback>
                                    {errors.projectDescription}
                                </FormFeedback>
                            )}
                        </FormGroup>
                        <Button
                            type="submit"
                            className="m-t-20 submit-btn"
                            disabled={loading || !countriesList.length || !currencyList.length}
                            onClick={() => resetForm()}
                        >
                            <span>
                                Get in Touch <i className="ti-arrow-right"></i>
                            </span>
                        </Button>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
};

export default FormComponent;
