/* eslint-disable */
import React, { Fragment } from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from 'reactstrap';
import { footerPageData } from '../../common';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer4 b-t spacer pb-0">
            <Container>
                <div className='varahi-soft'>{footerPageData?.companyName}</div>
                <div className='d-flex justify-content-start align-self-start gap-5 pb-4'>
                    <div className='w-75'>
                        <h4 className='startProject m-0'>{footerPageData?.title}</h4>
                    </div>
                    <div className='d-flex justify-content-end text-end flex-column'>
                        <div className='d-flex justify-content-end align-self-end w-75'>
                            {footerPageData?.rightContent}
                        </div>
                        <div className='d-flex justify-content-end m-t-20'>
                            <Link to="/contact" className='d-flex justify-content-end align-items-center gap-2 View-All-button px-4 btn' style={{ width: 'fit-content' }}>
                                <p className='m-0'>{footerPageData?.btnText}</p>
                                {footerPageData?.btnIcon}
                            </Link>
                        </div>
                    </div>
                </div>
                <Row>
                    {footerPageData?.listData?.map((list, index) => {
                        return (
                            <Col key={index} lg="3" md="6" className={`${list?.navigateList?.length > 0 ? 'navigate' : list?.ourServices?.length > 0 ? 'our-services' : list?.ourPolicies?.length > 0 ? 'our-policies' : ""} m-b-30`}>
                                <h5 className="m-b-20">{list?.title}</h5>
                                {list?.contactUsList?.length > 0 && list?.contactUsList?.map((item, index) => {
                                    return (
                                        <div key={index} className='d-flex gap-3 address'>
                                            <div className={`${index === 0 ? 'w-25' : ''}`}>
                                                {item?.icon}
                                            </div>
                                            <p>
                                                {item?.subTitle && <Fragment><span>{item?.subTitle}</span> <br /></Fragment>}
                                                {item?.text}
                                            </p>
                                        </div>
                                    )
                                })}
                                {list?.navigateList?.length > 0 && <div className='d-flex flex-wrap justify-content-start align-self-start'>
                                    {list?.navigateList?.length > 0 && list?.navigateList?.map((nav, navIndex) => {
                                        return (
                                            <div key={navIndex} className='w-50'>
                                                <Link className='w-100' to={nav?.link}>
                                                    <p className='m-0'>{nav?.text}</p>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </div>}
                                {list?.ourServices?.length > 0 && list?.ourServices?.map((service, serviceIndex) => {
                                    return (
                                        <Link key={serviceIndex} className='w-100' to={service?.link}>
                                            <p className='m-0'>{service?.text}</p>
                                        </Link>
                                    )
                                })}
                                {list?.ourPolicies?.length > 0 && <div className='my-link'>
                                    {list?.ourPolicies?.length > 0 && list?.ourPolicies?.map((policies, policiesIndex) => {
                                        return (
                                            <Link key={policiesIndex} className='w-100' to={policies?.link}>
                                                <p className='m-0'>{policies?.text}</p>
                                            </Link>
                                        )
                                    })}
                                    <div className='m-t-30'>
                                        <h5 className="m-b-20">{footerPageData?.followUs?.title}</h5>
                                        <div className="round-social light d-flex no-block">
                                            {footerPageData?.followUs?.icons?.map((follow, followIndex) => {
                                                return (
                                                    <Link target='_blank' key={followIndex} className='link no-block d-flex align-items-center justify-content-center' to={follow?.link}>
                                                        {follow?.icon}
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>}
                            </Col>
                        )
                    })}
                </Row>
                <div className="f4-bottom-bar">
                    <Row>
                        <Col md="12">
                            <div className="d-flex font-14 justify-content-center">
                                <div className="m-t-10 m-b-10 copyright-t1">{footerPageData?.bottomFirstBarText}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <div className="f4-bottom-bar py-2 mt-0" style={{ background: '#272727' }}>
                <Row>
                    <Col md="12">
                        <div className="d-flex font-14 justify-content-center">
                            <div className="m-t-10 m-b-10 copyright">{footerPageData?.copyRightText}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
export default Footer;
