import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
// import { HashLink as Link } from 'react-router-hash-link';
import ellipseImageTop from '../../assets/images/landingpage/Ellipse1880.png'
import ellipseImageBottom from '../../assets/images/landingpage/Ellipse1879.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const WeAreVarahiSoft = ({ projectsOngoingData }) => {
    const resentProjectData = projectsOngoingData;
    return (
        <section className='Projects-Ongoing'>
            <Container className='top-container'>
                <Row className="justify-content-start">
                    <Col lg="9" md="9" className="align-self-start">
                        <h4>{resentProjectData?.title}</h4>
                        <p className='w-75 m-0'>{resentProjectData?.text}</p>
                    </Col>
                </Row>
            </Container>
            <div className="acss-1kbtli">
                <div className='position-absolute' style={{ inset: 0 }}>
                    <div className="acss-lbif7z acss-b61x89">
                        <img className="acss-61n50j acss-118odl4" src={ellipseImageTop} alt="ellipseImage" />
                        <img className="acss-61n50j acss-x14t9p" src={ellipseImageBottom} alt="" />
                    </div>
                    <div className="acss-lbif7z acss-wom5xg">
                        <img className="acss-61n50j acss-1ptcy1b" src={ellipseImageTop} alt="" />
                        <img className="acss-61n50j acss-1ht8015" src={ellipseImageBottom} alt="" />
                    </div>
                    <div></div>
                </div>
                <Container className='acss-18azjcp' >
                    <div className='custom-pending-top'>
                        <Row className='Fre8-Pro'>
                            {resentProjectData?.projects?.map((project, index) => {
                                const isOdd = index % 2 !== 0; // Check if the index is odd
                                return (
                                    isOdd ? (
                                        <Fragment key={index}>
                                            <Col lg="6" md='6' className='pe-0 d-flex justify-content-center align-items-center '>
                                                <div className='pe-5 ps-2 custom-p-5'>
                                                    <h1>{project?.title}</h1>
                                                    <p>{project?.text}</p>
                                                </div>
                                            </Col>
                                            <Col lg="6" md='6' className='ps-0'>
                                                <LazyLoadImage
                                                    className='w-100'
                                                    alt={'Fre8-Pro'}
                                                    // height={image.height}
                                                    src={project?.projectImg} // use normal <img> attributes as props
                                                // width={image.width}
                                                />
                                                {/* <span>test</span> */}
                                                {/* <img className='w-100' src={project?.projectImg} alt='Fre8-Pro' /> */}
                                            </Col>
                                        </Fragment>
                                    ) : (
                                        <Fragment key={index}>
                                            <Col lg="6" md='6' className='ps-0'>
                                                <LazyLoadImage
                                                    className='w-100'
                                                    alt={'Fre8-Pro'}
                                                    // height={image.height}
                                                    src={project?.projectImg} // use normal <img> attributes as props
                                                // width={image.width}
                                                />
                                                {/* <img className='w-100' src={project?.projectImg} alt='Fre8-Pro' /> */}
                                            </Col>
                                            <Col lg="6" md='6' className='pe-0 d-flex justify-content-center align-items-center'>
                                                <div className='ps-5 pe-3 custom-p-5'>
                                                    <h1>{project?.title}</h1>
                                                    <p>{project?.text}</p>
                                                </div>
                                            </Col>
                                        </Fragment>
                                    )
                                )
                            })}
                        </Row>
                        <div className='d-flex no-block justify-content-between'>
                            <div className='Take-a-Look'>
                                <div className='d-flex justify-content-start align-items-start'>
                                    <div>
                                        <h1 className='m-0'>Take a Look</h1>
                                        <div className='d-flex gap-4 align-items-end'>
                                            <h1 className='m-0'>at What</h1>
                                            <div className='mb-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="153" height="2" viewBox="0 0 153 2" fill="none">
                                                    <path d="M0.656738 1.27482H153.005" stroke="#444444" strokeWidth="0.674754" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-circle-view-all'>
                                <Link to={'/project'}>
                                    <h2>View All</h2>
                                </Link>
                            </div>
                            <div className='Take-a-Look no-block d-flex align-items-end'>
                                <div>
                                    <h1 className='text-end m-0'>We Can Do</h1>
                                    <div className='d-flex no-block gap-4 align-items-end'>
                                        <div className='mb-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="153" height="2" viewBox="0 0 153 2" fill="none">
                                                <path d="M0.656738 1.27482H153.005" stroke="#444444" strokeWidth="0.674754" />
                                            </svg>
                                        </div>
                                        <h1 className='text-end m-0'>For You</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default WeAreVarahiSoft;
