import React, { useEffect } from "react";
import PropTypes from "prop-types";
// core components
import Header from "../../components/header/header";
import HeaderBanner from "../../components/banner/banner";
import Footer from "../../components/footer/footer";
// sections for this page
import WeAreVarahiSoft from "../../components/WeAreVarahiSoft/weAreVarahiSoft";
import IndustriesWeSpecialize from "../../components/IndustriesWeSpecialize/IndustriesWeSpecialize";
import ProjectsOngoing from "../../components/ProjectsOngoing/ProjectsOngoing";
import SpecializedTechnologies from "../../components/SpecialisedinTechnologies/SpecialisedinTechnologies";
import TechIndustryQuality from "../../components/TechIndustryQuality/TechIndustryQuality";
import ClientCount from "../../components/ClientCount/ClientCount";
import CaseStudy from "../../components/CaseStudy/CaseStudy";
import YourIdea from "../../components/YourIdea/YourIdea";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import OurLatestBlogs from "../../components/OurLatestBlogs/OurLatestBlogs";
import ContactUsComponent from "../../components/ContactUsComponent/ContactUsComponent";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import { aboutPageData, clientCounterData, FrequentlyAskedQuestionsData, homePageData, techIndustryQualityData } from "../../common";
import { getIPGeoLocation } from "../../common/GeneralUtils";
// import Loadable from "../../components/Loadable"


// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const HeaderBanner = Loadable(
//     lazy(() => import('../../components/banner/banner'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const WeAreVarahiSoft = Loadable(
//     lazy(() => import('../../components/WeAreVarahiSoft/weAreVarahiSoft'))
// )
// const IndustriesWeSpecialize = Loadable(
//     lazy(() => import('../../components/IndustriesWeSpecialize/IndustriesWeSpecialize'))
// )
// const ProjectsOngoing = Loadable(
//     lazy(() => import('../../components/ProjectsOngoing/ProjectsOngoing'))
// )
// const SpecializedTechnologies = Loadable(
//     lazy(() => import('../../components/SpecialisedinTechnologies/SpecialisedinTechnologies'))
// )
// const TechIndustryQuality = Loadable(
//     lazy(() => import('../../components/TechIndustryQuality/TechIndustryQuality'))
// )
// const ClientCount = Loadable(
//     lazy(() => import('../../components/ClientCount/ClientCount'))
// )
// const CaseStudy = Loadable(
//     lazy(() => import('../../components/CaseStudy/CaseStudy'))
// )
// const YourIdea = Loadable(
//     lazy(() => import('../../components/YourIdea/YourIdea'))
// )
// const CustomerReviews = Loadable(
//     lazy(() => import('../../components/CustomerReviews/CustomerReviews'))
// )
// const OurLatestBlogs = Loadable(
//     lazy(() => import('../../components/OurLatestBlogs/OurLatestBlogs'))
// )
// const ContactUsComponent = Loadable(
//     lazy(() => import('../../components/ContactUsComponent/ContactUsComponent'))
// )
// const FrequentlyAskedQuestions = Loadable(
//     lazy(() => import('../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'))
// )



const HomeScreen = () => {

    useEffect(() => {
        getIPGeoLocation()
    }, [])

    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <HeaderBanner bannerImg={homePageData?.bannerImg} data={homePageData?.headerData} />
                    <WeAreVarahiSoft varahiSoftData={homePageData?.weAreVarahiSoftData} />
                    <IndustriesWeSpecialize industriesWeSpecializeData={homePageData?.industriesWeSpecialize} />
                    <ProjectsOngoing projectsOngoingData={homePageData?.recentProjects} />
                    <SpecializedTechnologies data={aboutPageData?.techIndustry} />
                    <TechIndustryQuality techIndustryQualityData={techIndustryQualityData} />
                    <ClientCount ClientCountData={clientCounterData} />
                    <CaseStudy />
                    <YourIdea yourIdeaData={homePageData?.yourIdea} />
                    <CustomerReviews />
                    <OurLatestBlogs />
                    <ContactUsComponent />
                    <FrequentlyAskedQuestions faqData={FrequentlyAskedQuestionsData} />
                </div>
            </div>
            <Footer />
        </div>
    );
}

HomeScreen.propTypes = {
    classes: PropTypes.object
};

export default HomeScreen;
