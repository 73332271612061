import React, { useState } from 'react';
import { Carousel, CarouselIndicators, CarouselItem, Container } from 'reactstrap';
import { items } from '../../common';
// import { HashLink as Link } from 'react-router-hash-link';
import MobileSlider from './MobileSlider';
import { Link } from 'react-router-dom';


const IndustriesWeSpecialize = ({ industriesWeSpecializeData }) => {
    const pageData = industriesWeSpecializeData;
    // console.log('-----pagedata--->',pageData)
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item, index) => (
        <CarouselItem
            key={index}
            className="custom-tag"
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
        >
            <div className='d-flex gap-2 w-100'>
                {item?.data?.map((content, contentIndex) => {
                    return <div key={contentIndex} className='Product-Engineering w-25'>
                        <div className='product-inner'>
                            <div>
                                {content?.icon}
                            </div>
                            <div>
                                <h5>{content?.text}</h5>
                                <span>{content?.paragraph}</span>
                            </div>
                        </div>
                    </div>
                })}
            </div>
            {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
        </CarouselItem>
    ));


    return (
        <section className='industries-we-specialize'>
            <div className='custom-pending-top'>
                <div className='my-background'>
                    <div className='product'>
                        <Container className='custom-padding-py-100' >
                            <div className='d-flex justify-content-start align-self-start gap-5 pb-4'>
                                <Link to={'/industries'}>
                                    <div className=''>
                                        <h4>{pageData?.title}</h4>
                                        <p className='m-0'>{pageData?.text}</p>
                                    </div>
                                </Link>
                                <div className='d-flex no-block justify-content-end align-self-end w-50'>
                                    <Link to={'/industries'} className='View-All-button px-4'>
                                        <p className='m-0'>View All</p>
                                    </Link>
                                </div>
                            </div>
                            <Carousel
                                className='py-5 mt-4 web-display-block'
                                activeIndex={activeIndex}
                                next={next.bind(null)}
                                previous={previous.bind(null)}
                            >
                                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex.bind(null)} />
                                {slides}
                                {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous.bind(null)} /> */}
                                {/* <CarouselControl direction="next" directionText="Next" onClickHandler={next.bind(null)} /> */}
                            </Carousel>
                            <div className='mobile-display-block'>
                                <MobileSlider />
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default IndustriesWeSpecialize;
