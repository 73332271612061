import PropTypes from 'prop-types'
import React from 'react'
const Toasts = ({ toastsList }) => {
  return (
    <>
      <div
        className="toast-container "
        style={{
          position: 'fixed',
          width: '30%',
          right: '-1%',
          top: '2%',
          borderRadius: '6px',
          padding: 6,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          zIndex: '9999999'
        }}
      >
        {toastsList?.length > 0 && toastsList?.map((item, i) => {
          return (
            <div
              className="toast show"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              key={i}
            >
              <div
                className="toast-header"
                style={{
                  backgroundColor: item?.color || '#054757',
                  color: '#fff',
                }}
              >
                <img src={item?.imageUrl} className="rounded me-2" alt="" />
                <strong className="me-auto">{item?.type}</strong>
                <small className="text">just now</small>
                <button
                  type="button"
                  id="closeBtn"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                ></button>
              </div>
              <div className="toast-body" style={{ color: '#000' }}>
                <ul>
                  {Array.isArray(item?.name) ? (
                    item?.name?.map((errorName, inx) => (
                      <li key={inx}>{errorName}</li>
                    ))
                  ) : (
                    <li>{item?.name}</li>
                  )}
                </ul>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Toasts

Toasts.propTypes = {
  toastsList: PropTypes.array,
}
