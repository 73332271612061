import axios from "axios";
export const ApiUrl = 'https://dev-fre8pro-backend.varahisoft.in/fre8pro-backend/v1';
export const GeolocationApiUrl = 'https://api.ipdata.co';
export const APIKey = 'e933d836ee34a5dbe76fc5feefd3432d24a01acb7b3891842d9f9b13';

// --------------------------------

export const chunkArray = (arr, size) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }
  return chunks;
};

// Define getCountries as an async function
export const getIPGeoLocation = async () => {
  try {
    await axios.get(`${GeolocationApiUrl}/?api-key=${APIKey}`).then((res) => {
      console.log('-------------res--------->', res);
      if (res?.status === 200) {
        getVisitorLocation(res?.data)
      }
    }).catch((error) => {
      console.error("Error submitting the form:", error);

    }); // Assuming no `values` parameter is required for POST request
  } catch (err) {
    console.debug(":Values from get currencies", err);

  }
};
// Define getCountries as an async function
export const getVisitorLocation = async (value) => {
  try {
    console.log('------------value--------->', value)
    const payload = {
      visitorLocationId: "string",
      ip: value?.ip,
      is_eu: value?.is_eu,
      city: value?.city,
      region: value?.region,
      region_code: value?.region_code,
      region_type: value?.region_type,
      country_name: value?.country_name,
      country_code: value?.country_code,
      continent_name: value?.continent_name,
      continent_code: value?.continent_code,
      latitude: value?.latitude,
      longitude: value?.longitude,
      postal: value?.postal,
      calling_code: value?.calling_code,
      flag: value?.flag,
      emoji_flag: value?.emoji_flag,
      emoji_unicode: value?.emoji_unicode
    }
    const res = await axios.post(`${ApiUrl}/varahi/visitorlocation`, payload);
    console.log('-----------res------>', res)
  } catch (err) {
    console.debug(":Values from get currencies", err);

  }
};

export const ErrorHandle = (error = {}, setToasts, customMessage, type, color) => {
  let customMessageIsArr = Array.isArray(customMessage)
    ? [...customMessage]
    : [customMessage]
  let errorMessage =
    error?.response?.data?.errorDetail?.errors?.uiErrorKey?.length > 0
      ? error?.response?.data?.errorDetail?.errors?.uiErrorKey?.map(
        (errorMessage) => errorMessage?.localMessage
      )
      : customMessageIsArr
  let item = [
    {
      name: errorMessage,
      type: type,
      color: color,
    },
  ]
  setToasts(item)
  setTimeout(() => {
    setToasts(null)
  }, 5000)

  if (
    (error?.response?.data?.errorDetail?.status === 500 ||
      error?.response?.data?.httpStatusCode === 500 ||
      error?.response?.status === 500) &&
    (error?.response?.data?.errorDetail?.developerMessage ===
      'getLastAccessedTime: Session already invalidated' ||
      error?.response?.data?.errorDetail?.developerMessage ===
      'Session object not found ')
  ) {
    window.location.href = '/'
  }

  return ''
}

