import React, { Fragment, useState } from 'react';
import { Container, Collapse, Row, Col } from 'reactstrap';
import { MinusIcon, PlusIcon } from '../../common/icon';
// import { HashLink as Link } from 'react-router-hash-link';
// import techImg from '../../assets/images/landingpage/smiling-executives-waiting-meeting.png'

const TechIndustryQuality = ({ techIndustryQualityData }) => {
    const techIndustryData = techIndustryQualityData;
    const [state, setState] = useState({
        articleOpen: 0,
        moreInfoOpen: true
    });

    const toggle = (index) => {
        setState({
            articleOpen: index,
            moreInfoOpen: !state.moreInfoOpen
        });
    };

    return (
        <section className='Tech-Industry-Quality'>
            <div className='tech-content py-5' style={{ backgroundImage: `url(${techIndustryData?.bgImg})` }}>
                <Container className="py-5 pe-5">
                    <Row className="justify-content-start">
                        <Col lg="7" md="7" className="align-self-start">
                            <div className='page-content pb-5'>
                                <h4>{techIndustryData?.pageHeading}</h4>
                                <span>{techIndustryData?.pageParagraph}</span>
                            </div>
                            <div className='tech-faq'>
                                {techIndustryData?.faqData?.map((faq, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <div className='d-flex justify-content-start align-items-center gap-3' onClick={() => toggle(index)} style={{ marginBottom: '1rem' }}>
                                                {state && index === state?.articleOpen ? <MinusIcon /> : <PlusIcon />}
                                                <h6>{faq?.title}</h6>
                                            </div>
                                            <Collapse isOpen={index === state?.articleOpen}>
                                                <div className='tech-faq-content'>
                                                    <p className='tech-faq-content-body'>
                                                        {faq?.paragraph}
                                                    </p>
                                                </div>
                                            </Collapse>
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default TechIndustryQuality;
